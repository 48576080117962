@font-face {
    font-family: 'YourLeagueFont';
    src: url('../fonts/open-sauce/OpenSauceTwo-Medium.ttf') format('truetype'),
        url('../fonts/open-sauce/OpenSauceTwo-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

:root {
    --background-color: white;
    --text-color: black;
    --primary-color: blue;
}

.dark-green-theme {
    --background-color: #01161e;
    --text-color: #e8f4e0;
    --primary-color: #10303d;
    --secondary-color: #eff6e0;
    --orange-color: #ef8c7a;
    --panel-color-0: #10303d;
    --panel-color-1: #aec3b0;
    --panel-color-2: #598392;
    --panel-color-3: #124559;
}

.light-green-theme {
    --background-color: white;
    --text-color: black;
    --primary-color: blue;
}

// Your League CSS

body {
    background-color: var(--background-color) !important;
    font-family: 'YourLeagueFont', sans-serif !important;
}

.yl-root-background {
    background-color: var(--background-color) !important;
}

.yl-border-bottom {
    border-bottom: 1px solid var(--panel-color-1) !important;
}

.yl-border-right {
    border-right: 1px solid var(--panel-color-1) !important;
}

.yl-text-color {
    color: var(--text-color) !important;
}

.yl-primary-text {
    color: var(--primary-color) !important;
}

.yl-secondary-text {
    color: var(--secondary-color) !important;
}

.yl-panel-primary {
    background-color: var(--panel-color-0) !important;
}

.yl-panel-secondary {
    background-color: var(--panel-color-1) !important;
}

.yl-panel-two {
    background-color: var(--panel-color-2) !important;
}

.yl-panel-three {
    background-color: var(--panel-color-3) !important;
}


.yl-button-secondary.p-button.p-button-text.p-button-plain:not(:disabled):hover {
    background: var(--panel-color-1) !important;
    color: var(--primary-color) !important;

    .yl-secondary-text {
        color: var(--primary-color) !important;
    }
}

.clickable {
    cursor: pointer;
}

.ellipsis {
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Hides the overflowed text */
    text-overflow: ellipsis;
    /* Adds the ellipsis (...) */
    width: calc(98%);
}