/* You can add global styles to this file, and also import other style files */
/* @import '../src/assets/global.css';
@import '../src/assets/bootstrap/dist/css/bootstrap.min.css';
@import '../src/assets/bootstrap/dist/css/dashboard.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
@import '../src/assets/simple-sidebar.css'; */

$gutter: 1rem; //for primeflex grid system

@import "assets/sakai/layout/styles/theme/lara-light-blue/theme.css";
@import "assets/sakai/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

// Bootstrap
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
@import "../node_modules/bootstrap/dist/css/bootstrap-utilities.min.css";

/* Font Awesome */
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

/* PrimeNG */
@import "assets/sakai/demo/styles/flags/flags.css";
@import "assets/sakai/demo/styles/badges.scss";
@import "assets/sakai/demo/styles/code.scss";

// Your League Custom Css
@import "assets/themes/your-league-themes.scss";

//line icons
@import "assets/lineicons/regular-icon-font-free/css/icons.css";
@import "assets/lineicons/regular-icon-font-free/lineicons.css";